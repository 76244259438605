import { useState, useCallback, useEffect, createRef } from "react";
import SlideEffect from "../Animations/SlideEffect";
import Button from "../Button/Button";
import { toast } from "react-toastify";
import apiClient from "../../api/apiClient";
import SuccessRegistration from "./SuccessRegistration";
import Input from "../Input/Input";
import S3 from "react-aws-s3";
import ReCAPTCHA from "react-google-recaptcha";
window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
    bucketName: "softecnu-website-bucket",
    region: "me-south-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    dirName: "resumes24",
};

const S3ClientResume = new S3(config);
const S3ClientFYP = new S3({
    ...config,
    dirName: "fyp24",
});

const allowedTypes = ["pdf", "docx"];
const maxSize = 10485760; // 10 MB in bytes

const checkFileRequirements = (file) => {
    const fileType = file?.name.split(".").pop();

    if (!allowedTypes.includes(fileType)) {
        toast.error("Please upload a PDF/Word file only!");
        return false;
    }
    if (file.size > maxSize) {
        toast.error("Size of file must be less than 10MB!");
        return false;
    }

    return true;
};

const TeamMembersRegistration = ({ form, setForm }) => {
    const minTeamSize = form.selectedCompetition.min_team_size;
    const recaptchaRef = createRef();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [requirements, setRequirements] = useState([]);
    const [consent, setConsent] = useState(false);
    const [codeOfConductConsent, setCodeOfConductConsent] = useState(false);

    async function uploadFileToS3(file, filename, type = "resume") {
        const uploaderFunc = type === "resume" ? S3ClientResume : S3ClientFYP;

        try {
            const res = await uploaderFunc.uploadFile(file, filename);
            return res.location;
        } catch (err) {
            console.error(err);
            toast.error("Error while uploading file!");
            return null;
        }
    }

    const handleNext = useCallback(
        async (e) => {
            e.preventDefault();
            // get recaptcha token
            const token = await recaptchaRef.current.getValue();
            // if not token then return
            if (!token) {
                toast.error("Please verify you are not a robot!");
                recaptchaRef.current.reset();
                return;
            }

            if (!codeOfConductConsent) {
                toast.error("Please accept the Code of Conduct to proceed");
                return;
            }

            if (!(form.competitionId && form.teamName && form.institute)) {
                toast.error("Please fill all the fields");
                return;
            }

            let enteredMembers = form.teamMembers.filter(
                (member) =>
                    member.name || member.gender || member.email || member.phone || member.cnic
            );

            // check that minimun amount of members have filled the data
            enteredMembers.slice(0, minTeamSize).forEach((member) => {
                if (member.phone.length !== 11) {
                    toast.error(
                        "Please enter phone number in the format as requested!"
                    );
                    return;
                }

                if (member.cnic.length !== 13) {
                    toast.error(
                        "Please enter CNIC in the format as requested!"
                    );
                    return;
                }

                if (
                    !(member.name || member.email || member.phone || member.gender) ||
                    (form.selectedCompetition.cnic_required && !member.cnic)
                ) {
                    toast.error("Please fill all the required fields!");
                    return;
                }
            });

            enteredMembers.forEach((member, idx) => {
                if (
                    !(
                        member.name &&
                        member.gender &&
                        member.email &&
                        member.phone &&
                        (!form.selectedCompetition.cnic_required || member.cnic)
                    )
                ) {
                    toast.error("Please fill all fields for your team members");
                    return;
                }

                for (const req of requirements) {
                    // if requirement is not for FYP and member has FYP report then skip
                    if (idx !== 0 && req?.name?.includes("FYP")) continue;
                    if (req.required && !member[req.name]) {
                        toast.error(
                            `Please fill all the required members' fields.`
                        );
                        return;
                    }
                }
            });

            let validFiles = true;

            enteredMembers.forEach((member) => {
                const resumeFile = member.Resume;
                const fypFile = member["FYP Report"];

                if (resumeFile && !checkFileRequirements(resumeFile)) {
                    validFiles = false;
                    return;
                }

                if (fypFile && !checkFileRequirements(fypFile)) {
                    validFiles = false;
                    return;
                }
            });

            if (!validFiles) return;

            setLoading(true);

            try {
                setLoading(true);

                const updatedMembers = await Promise.all(
                    enteredMembers.map(async (member) => {
                        const resumeFile = member.Resume;
                        const fypFile = member["FYP Report"];

                        const resumeUrl = resumeFile
                            ? await uploadFileToS3(
                                  resumeFile,
                                  `${form.teamName} - ${member.name} - Resume`
                              )
                            : null;

                        const fypUrl = fypFile
                            ? await uploadFileToS3(
                                  fypFile,
                                  `${form.teamName} - FYP Report`,
                                  "fyp"
                              )
                            : null;

                        // append resume and fyp urls to member object if they exist
                        if (resumeUrl) member.Resume = resumeUrl;
                        if (fypUrl) member["FYP Report"] = fypUrl;

                        return member;
                    })
                );
                const data = {
                    competition: form.competitionId,
                    name: form.teamName,
                    institute: form.institute,
                    ambassador: form.ambassador || null,
                    accommodation: form.config.isAccommodationRegOpen
                        ? form.accommodation || 0
                        : 0,
                    food: form.config.isFoodRegOpen ? form.food || 0 : 0,
                    members: updatedMembers,
                    token,
                };

                try {
                    const res = await apiClient.post(
                        "/registration/team/",
                        data
                    );
                    setForm((form) => ({
                        ...form,
                        competitionId: "",
                        teamName: "",
                        institute: "",
                        ambassador: "",
                        discipline: "",
                        accommodation: "",
                        members: [],
                    }));
                    setSuccess(res.data);
                } catch (err) {
                    console.log(err);
                    toast.error(`Registration Failed! ${err.message}`);
                } finally {
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                toast.error(`Registration Failed! ${error.message}`);
            } finally {
                setLoading(false);
                recaptchaRef.current.reset();
            }
        },
        [form, minTeamSize, setForm, requirements, codeOfConductConsent, recaptchaRef]
    );

    function handlePrev() {
        setForm({
            ...form,
            step: form.step - 1 >= 0 ? form.step - 1 : 0,
        });
    }

    const handleChange = (e, index) => {
        const newMembers = form.teamMembers;
        if (e.target.files)
            newMembers[index][e.target.name] = e.target.files[0];
        else newMembers[index][e.target.name] = e.target.value;

        setForm({
            ...form,
            teamMembers: newMembers,
        });
    };

    const compId = form.selectedCompetition.id;

    useEffect(() => {
        const fetchRequirements = async () => {
            if (compId) {
                const data = {
                    competition: compId,
                };

                const result = await apiClient.post(
                    "/competition/requirements/",
                    data
                );

                setRequirements(result.data);
            }
        };
        fetchRequirements();
    }, [compId]);

    return (
        <>
            {success === "" ? (
                <form className="px-5" onSubmit={handleNext}>
                    <SlideEffect>
                        <h1 className="mb-2 text-center">
                            Min. Members:{" "}
                            <span className="font-bold">{minTeamSize}</span>
                        </h1>
                        <div className="flex flex-col w-full">
                            {form.teamMembers.map((member, index) => (
                                <div className="mt-10" key={index}>
                                    <Input
                                        text={`Member ${index + 1} Name`}
                                        placeholder={`Member ${index + 1} Name`}
                                        value={member.name}
                                        name="name"
                                        onChange={(e) => handleChange(e, index)}
                                        required={
                                            index < minTeamSize ||
                                            form.teamMembers[index].name !== ""
                                        }
                                    />

                                    <Input
                                        text={`Member ${index + 1} Email`}
                                        type="email"
                                        placeholder={`Member ${
                                            index + 1
                                        } Email`}
                                        value={member.email}
                                        name="email"
                                        onChange={(e) => handleChange(e, index)}
                                        required={
                                            index < minTeamSize ||
                                            form.teamMembers[index].name !== ""
                                        }
                                    />

                                    <div className="md:grid-cols-2 md:gap-6 grid">
                                        <Input
                                            text={`Member ${index + 1} CNIC`}
                                            placeholder="0000000000000"
                                            pattern="[0-9]{13}"
                                            value={member.cnic}
                                            name="cnic"
                                            onChange={(e) =>
                                                handleChange(e, index)
                                            }
                                            required={
                                                (index < minTeamSize ||
                                                    form.teamMembers[index]
                                                        .name !== "") &&
                                                form.selectedCompetition
                                                    .cnic_required
                                            }
                                        />
                                        <Input
                                            text={`Member ${index + 1} Phone`}
                                            placeholder="03001234567"
                                            pattern="[0-9]{11}"
                                            value={member.phone}
                                            name="phone"
                                            onChange={(e) =>
                                                handleChange(e, index)
                                            }
                                            required={
                                                index < minTeamSize ||
                                                form.teamMembers[index].name !==
                                                    ""
                                            }
                                        />
                                    </div>

                                    <Input
                                        text={`Member ${index + 1} Gender`}
                                        type="select-gender"
                                        value={member.gender}
                                        name="gender"
                                        onChange={(e) => handleChange(e, index)}
                                        list={[
                                            { id: 1, name: "Male", value: "male" },
                                            { id: 2, name: "Female", value: "female" }
                                        ]}
                                        required={index < minTeamSize || form.teamMembers[index].name !== ""}
                                    />

                                    {requirements.map((requirement, idx) => {
                                        // // if idx!==1 and requirement field has FYP in field name then skip
                                        if (
                                            index !== 0 &&
                                            requirement.name.includes("FYP")
                                        )
                                            return null;
                                        return (
                                            <Input
                                                key={requirement.name}
                                                text={requirement.name}
                                                placeholder={`Enter Member ${
                                                    index + 1
                                                } ${requirement.name}`}
                                                value={
                                                    // ! DANGER ! // WORK AROUND // ! DANGER ! //
                                                    member[requirement._name]
                                                }
                                                type={requirement.type}
                                                name={requirement.name}
                                                onChange={(e) =>
                                                    handleChange(e, index)
                                                }
                                                required={
                                                    (index < minTeamSize ||
                                                        form.teamMembers[index]
                                                            .name !== "") &&
                                                    requirement.required
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-col gap-4 mt-6">
                            <div className="flex items-center">
                                <input
                                    id="consent"
                                    type="checkbox"
                                    name="consent"
                                    checked={consent}
                                    required
                                    onChange={(e) => setConsent(e.target.checked)}
                                    className="md:focus:ring-blue-500 md:focus:ring-2 md:w-5 md:h-5 w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="consent"
                                    className="md:mx-2 mx-4 text-sm font-medium text-gray-900"
                                >
                                    I consent to the sharing of this information
                                    with potential sponsors.
                                </label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="codeOfConduct"
                                    type="checkbox"
                                    name="codeOfConduct"
                                    checked={codeOfConductConsent}
                                    required
                                    onChange={(e) => setCodeOfConductConsent(e.target.checked)}
                                    className="md:focus:ring-blue-500 md:focus:ring-2 md:w-5 md:h-5 w-8 h-8 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                />
                                <label
                                    htmlFor="codeOfConduct"
                                    className="md:mx-2 mx-4 text-sm font-medium text-gray-900"
                                >
                                    I have read, understood, and agree to abide by the{" "}
                                    <a
                                        href="/code-of-conduct.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:text-blue-800 underline"
                                    >
                                        Code of Conduct
                                    </a>
                                </label>
                            </div>
                        </div>
                        <ReCAPTCHA
                            className="my-5"
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            ref={recaptchaRef}
                        />
                    </SlideEffect>
                    <div className="md:flex-row flex flex-col-reverse gap-5 mt-10">
                        <Button
                            type="button"
                            small
                            outlined
                            text="Back"
                            onClick={handlePrev}
                        />
                        <Button
                            small
                            text="Register"
                            disabled={!consent || !codeOfConductConsent}
                            loading={loading}
                        />
                    </div>
                </form>
            ) : (
                <SuccessRegistration
                    form={form}
                    setForm={setForm}
                    message={success}
                />
            )}
        </>
    );
};

export default TeamMembersRegistration;
