import Layout from "../../components/Layouts/Layout";
import SlideEffect from "../../components/Animations/SlideEffect";

const AboutUs = () => {
    return (
        <Layout size={8} title="About Us">
            <SlideEffect>
                {/* Hero Section */}
                <section className="mb-12 mt-12">
                    <div className="text-center max-w-5xl mx-auto">
                        <h2 className="text-3xl font-bold text-[#664ed8] mb-4">Welcome to SOFTEC</h2>
                        <p className="text-gray-600 text-lg">
                            SOFTEC is Pakistan's largest and most prestigious international IT event, organized annually by the students of FAST National University, Lahore. Since its inception, SOFTEC has grown into a three-day festival that attracts thousands of participants, industry professionals, and tech enthusiasts. Our vision, "Beyond Limits," reflects our commitment to pushing technological boundaries and fostering innovation.
                        </p>
                    </div>
                </section>

                {/* Mission & Vision Section */}
                <section className="grid md:grid-cols-2 gap-8 mb-12">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold text-[#664ed8] mb-4">Our Mission</h3>
                        <p className="text-gray-600">
                            To create a bridge between academia and industry by providing students with opportunities to showcase their technical expertise, connect with professionals, and stay updated with emerging trends.
                        </p>
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold text-[#664ed8] mb-4">Our Vision</h3>
                        <p className="text-gray-600">
                            To be the leading IT event that nurtures talent, encourages innovation, and establishes meaningful industry-academia collaborations.
                        </p>
                    </div>
                </section>

                {/* History Section */}
                <section className="mb-12">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold text-[#664ed8] mb-4">Our History</h3>
                        <p className="text-gray-600">
                            Established in 1995, SOFTEC has grown from a small-scale student initiative to an internationally recognized tech extravaganza. 
                            Over the years, it has hosted thousands of participants from universities across Pakistan and beyond, 
                            offering unparalleled opportunities for students to compete, innovate, and network.
                        </p>
                    </div>
                </section>

                {/* What We Do Section */}
                <section className="mb-12">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold text-[#664ed8] mb-4">What We Do</h3>
                        <div className="grid md:grid-cols-3 gap-6">
                            <div>
                            <h4 className="text-xl font-semibold mb-3">Competitions</h4>
<p className="text-gray-600">
    We organize various competitions, including both technical and non-technical challenges such as hackathons, coding competitions, robotics challenges, and more.
</p>

                            </div>
                            <div>
                                <h4 className="text-xl font-semibold mb-3">Workshops & Seminars</h4>
                                <p className="text-gray-600">
                                    Industry experts conduct workshops and seminars to share knowledge and latest trends in technology.
                                </p>
                            </div>
                            <div>
                                <h4 className="text-xl font-semibold mb-3">Networking</h4>
                                <p className="text-gray-600">
                                    We create opportunities for students to connect with industry professionals and potential employers.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Impact Section */}
                <section className="mb-12">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-2xl font-bold text-[#664ed8] mb-4">Our Impact</h3>
                        <div className="grid md:grid-cols-2 gap-8">
                            <div>
                                <h4 className="text-xl font-semibold mb-3">Student Development</h4>
                                <p className="text-gray-600">
                                    Through our events, students develop practical skills, gain industry exposure, and build their professional network.
                                </p>
                            </div>
                            <div>
                                <h4 className="text-xl font-semibold mb-3">Industry Connection</h4>
                                <p className="text-gray-600">
                                    We bridge the gap between academia and industry by bringing together students and leading tech companies.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </SlideEffect>
        </Layout>
    );
};

export default AboutUs; 