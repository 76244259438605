import SlideEffect from "../Animations/SlideEffect";
import Button from "../Button/Button";
import Input from "../Input/Input";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import apiClient from "../../api/apiClient";

const citiesOfPakistan = ["Abbottabad", "Ahmadpur East", "Bagh", "Bahawalnagar", "Bahawalpur", "Bannu", "Bhalwal", "Badin", "Charsadda", "Chakwal", "Chiniot", "Chishtian", "Dadu", "Dera Ghazi Khan", "Dera Ismail Khan", "Faisalabad", "Ghotki", "Gilgit", "Gojra", "Gujranwala", "Gujrat", "Hafizabad", "Hangu", "Haripur", "Haroonabad", "Hasilpur", "Hyderabad", "Islamabad", "Jacobabad", "Jaranwala", "Jatoi", "Jhelum", "Kamalia", "Kamoke", "Kasur", "Kabal", "Kahror Pakka", "Karachi", "Khanewal", "Khairpur", "Khuzdar", "Kot Adu", "Kotli", "Lahore", "Larkana", "Layyah", "Lodhran", "Mandi Bahauddin", "Mardan", "Mianwali", "Mirpur (AJK)", "Mirpur Khas", "Mingora", "Multan", "Muzaffargarh", "Muzaffarabad", "Narowal", "Nawabshah", "Okara", "Pakpattan", "Pano Aqil", "Peshawar", "Quetta", "Rahim Yar Khan", "Rawalpindi", "Sadiqabad", "Sahiwal", "Samundri", "Sanghar", "Sargodha", "Sheikhupura", "Shahdadkot", "Shahdadpur", "Shahkot", "Shikarpur", "Shorkot", "Sialkot", "Skardu", "Sukkur", "Talagang", "Tando Adam", "Tando Allahyar", "Tando Muhammad Khan", "Turbat", "Umerkot", "Vehari", "Wah Cantonment"];

const TeamRegistration = ({ form, setForm }) => {
    const [competitionData, setCompetitionData] = useState(null);

    // Fetch detailed competition data for the selected competition
    useEffect(() => {
        const fetchCompetitionDetail = async () => {
            if (form.selectedCompetition?.id) {
                try {
                    const response = await apiClient.get(`/competitions_listable/${form.selectedCompetition.id}/`);
                    setCompetitionData(response.data);
                } catch (error) {
                    console.error("Error fetching competition details:", error);
                    // Fallback to using the data we already have
                    setCompetitionData(form.selectedCompetition);
                }
            }
        };

        fetchCompetitionDetail();
    }, [form.selectedCompetition?.id]);

    function handleNext(e) {
        e.preventDefault();
        setForm({ ...form, step: form.step + 1 });
    }

    function handlePrev() {
        setForm({
            ...form,
            step: form.step - 1 >= 0 ? form.step - 1 : 0,
        });
    }

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const shouldShowFoodAccommodation =
        form.selectedCompetition?.name === "Web Development Hackathon" ||
        form.selectedCompetition?.name === "App Development Competition" ||
        (form.city && form.city !== "Lahore"); // Ensure city is selected and not Lahore

    // Use either the fetched competition data or fall back to form.selectedCompetition
    const competition = competitionData || form.selectedCompetition;

    return (
        <form className="px-5" onSubmit={handleNext}>
            <SlideEffect>
                <div className="pb-10">
                    {/* Display sponsor if it exists */}
                    {competition?.sponsor && (
                        <section className="sponsor w-full mb-8">
                            <h3 className="text-xl font-semibold mb-3">Powered by</h3>
                            <div className="py-2 flex flex-col mx-auto max-w-[40rem] justify-center">
                                <img
                                    src={competition.sponsor.logo}
                                    className="object-contain mx-auto"
                                    width={250}
                                    alt={competition.sponsor.name}
                                />
                            </div>
                        </section>
                    )}

                    {shouldShowFoodAccommodation && form.config.isAccommodationRegOpen && (
                        <h4 className="text-lg">
                            <span className="font-semibold">Accommodation Fee: </span>
                            PKR. {form.config.accommodationFee} per person
                        </h4>
                    )}

                    {shouldShowFoodAccommodation && form.config.isFoodRegOpen && (
                        <h4 className="text-lg">
                            <span className="font-semibold">Food Fee: </span>
                            PKR. {form.config.foodFee} per person
                        </h4>
                    )}
                </div>

                <div className="flex flex-col w-full">
                    <Input
                        text="Team Name"
                        placeholder="Team Name"
                        value={form.teamName}
                        name="teamName"
                        onChange={handleChange}
                        required
                    />

                    <Input
                        text="Institute"
                        placeholder="Institute"
                        value={form.institute}
                        name="institute"
                        onChange={handleChange}
                        required
                    />

                    <div className="flex-1 mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-400">
                            City <span className="text-red-500">*</span>
                        </label>
                        <select
                            name="city"
                            value={form.city || ""}
                            onChange={handleChange}
                            className="bg-gray-50 focus:outline-none focus:shadow-input block w-full p-3 text-sm text-gray-400 border border-gray-400 rounded-lg"
                            required
                        >
                            <option value="" disabled>
                                Select City
                            </option>
                            {citiesOfPakistan.map((city) => (
                                <option key={city} value={city}>
                                    {city}
                                </option>
                            ))}
                        </select>
                    </div>

                    {shouldShowFoodAccommodation && (
                        <div className="flex">
                            {form.config.isFoodRegOpen && (
                                <Input
                                    text="Food Count"
                                    placeholder="Number of persons"
                                    value={form.food}
                                    min={0}
                                    type="number"
                                    max={form.selectedCompetition?.max_team_size}
                                    disabled={!form.config.isFoodRegOpen}
                                    name="food"
                                    onChange={handleChange}
                                    tooltip="Enter the number of persons you require food for. This fee provides you 6 meals per person."
                                />
                            )}

                            {form.config.isAccommodationRegOpen && (
                                <Input
                                    text="Accommodation"
                                    type="number"
                                    placeholder="Number of persons"
                                    value={form.accommodation}
                                    name="accommodation"
                                    onChange={handleChange}
                                    min={0}
                                    max={form.selectedCompetition?.max_team_size}
                                    disabled={!form.config.isAccommodationRegOpen}
                                    tooltip="Enter the number of persons you require accommodation (stay) for. This fee provides you stay at SOFTEC from Friday night to Sunday 6pm."
                                />
                            )}
                        </div>
                    )}

                    <Input
                        text="Ambassador/Discount Code"
                        placeholder="SF0000"
                        value={form.ambassador}
                        name="ambassador"
                        onChange={handleChange}
                    />
                </div>
            </SlideEffect>
            <div className="md:flex-row flex flex-col-reverse gap-6 mt-10">
                <Button
                    small
                    text="Back"
                    outlined
                    type="button"
                    onClick={handlePrev}
                />
                <Button small text="Next" />
            </div>
        </form>
    );
};

export default TeamRegistration;
