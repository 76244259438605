import Timer from "./Timer";
import FadeSlideIn from "../Animations/FadeSlideIn";
import { imagesArr } from "../../assets";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section id="introduction">
      <div
        className={`relative flex flex-col items-center justify-center w-full h-screen px-5 bg-black sm:px-20 md:px-0 hero-section bg-hero`}
      >
        <FadeSlideIn>
          <img
            className="select-none md:px-0 px-10 w-[30ch] sm:w-[50ch] md:w-[75ch] lg:w-[100ch]"
            src={imagesArr.Welcome}
            width="25ch"
            height={110}
            alt="welcome to softec 2025"
          />
        </FadeSlideIn>
        <p className="md:text-xl lg:text-2xl mt-3 text-sm font-bold text-center text-white">
          25th - 27th April 2025 at FAST NUCES, Lahore
        </p>
        <Timer />
        <div className="bottom-10 md:scale-100 absolute flex flex-wrap justify-center gap-4 scale-50">
          <Link
            to="/registration/competition"
            className="text-logo-indigo rounded-md hover:text-white hover:bg-transparent border-white border-[2px] px-10 py-4 font-bold hover:rounded-none uppercase bg-white focus:outline-none text-2xl text-center transition-all"
          >
            Register Now
          </Link>
          {/* <span
            onClick={() =>
              document
                .getElementById("timeline")
                .scrollIntoView({ behavior: "smooth" })
            }
            className="text-white cursor-pointer rounded-md hover:text-white hover:bg-transparent border-logo-indigo border-[2px] px-10 py-4 font-bold hover:rounded-none uppercase bg-logo-indigo focus:outline-none text-2xl text-center transition-all"
          >
            Event Timeline
          </span> */}
        </div>

      </div>
    </section>
  );
};

export default Hero;
