import Title from "../Title/Title";
const CompetitionSponsor = ({ selected }) => {
    return (
        <section className="sponsor w-full mt-10">
            <Title text="Powered By" />
            <div className="py-2 flex flex-col mx-10 sm:m-auto max-w-[40rem] justify-center">
                <img
                    src={selected.sponsor.logo}
                    className="object-contain mx-auto"
                    width={300}
                    alt={selected.sponsor.name}
                />
            </div>
        </section>
    );
};

export default CompetitionSponsor;
