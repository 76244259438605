import { useState, useRef } from "react";
import { Link } from "react-router-dom";

import { useQuery } from "react-query";
import Layout from "../../components/Layouts/Layout";
import HeroImage from "../../components/Image/HeroImage";
import CompetitionInfo from "../../components/Competitions/CompetitionInfo";
import CompetitionDetails from "../../components/Competitions/CompetitionDetails";
import CompetitionPrizes from "../../components/Competitions/CompetitionPrizes";
import CompetitionSponsor from "../../components/Competitions/CompetitionSponsor";
import CompetitionCollaborators from "../../components/Competitions/CompetitionCollaborators";
import RadioList from "../../components/Radio/RadioList";
import Title from "../../components/Title/Title";
import Loader from "../../components/Loader";
import apiClient from "../../api/apiClient";

const Competitions = () => {
  const [selectedCompetition, setSelectedCompetition] = useState(1);
  const targetElement = useRef();

  const { data: competitions, isLoading } = useQuery(
    "competitions",
    async () => {
      const response = await apiClient.get("/competitions_listable/");
      return response.data;
    },
    {
      cacheTime: 300000, // 5 minutes
    }
  );

  const handleClick = (id) => {
    setSelectedCompetition(id);
    targetElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const selected = competitions?.find(
    (competition) => competition.id === selectedCompetition
  );

  return (
    <Layout size={8} title="Competitions">
      <Title text="Competitions" />
      <Loader isLoading={isLoading}>
        <RadioList
          list={competitions}
          selected={selectedCompetition}
          handleClick={handleClick}
        />
        <div
          className="scroll-my-20 flex flex-col justify-center py-4"
          ref={targetElement}
        >
          {/* <HeroImage selected={selected} /> */}
          <section className="md:my-12 info w-full my-4">
            <div className="flex justify-center">
              <Link
                to={`/registration/competition/`}
                className="text-white bg-logo-indigo rounded-xl hover:text-black hover:bg-logo-indigo hover:rounded-xl border-white border-[2px] px-10 py-4 font-bold hover:rounded-none uppercase focus:outline-none text-2xl text-center transition-all"

              >
                Register Now
              </Link>
            </div>
            

            {selected?.sponsor && <CompetitionSponsor selected={selected} />}

            <Title text="Details" />
            

            <CompetitionInfo selected={selected} />
            <CompetitionDetails selected={selected} />
          </section>
          <CompetitionPrizes selected={selected} />

          

          {selected?.technical_collaborator?.length > 0 && (
            <CompetitionCollaborators selected={selected} />
          )}
        </div>
      </Loader>
    </Layout>
  );
};

export default Competitions;
